import ws from 'utils/Webservice'

import { addDays, format } from 'date-fns'

import msal from 'core/src/utils/auth'

const api = {
  all: (siteId: number, limit?: number) => {
    return ws<ArticleListV4>(`/articles?siteId=${siteId}${limit ? `&limit=${limit}` : ''}`, 'GET')
  },
  article: (id: string) => {
    return ws<ArticleDetailV4>(`/articles/${id}`, 'GET')
  },
  like: (siteId: number, id: string) => {
    return ws<void>(`/articles/${id}/like?siteId=${siteId}`, 'POST')
  },
  dislike: (siteId: number, id: string) => {
    return ws<void>(`/articles/${id}/like?siteId=${siteId}`, 'DELETE')
  },
  register: (news: ArticleDetailV4) => {
    if (!news.startDate || !news.endDate) {
      return Promise.reject('invalid dates')
    }

    const startTime = news.entireDay || !news.startTime ? '00:00:00' : news.startTime
    const endTime = news.entireDay || !news.endTime ? '00:00:00' : news.endTime

    const startDate = new Date(news.startDate)
    const endDate = news.entireDay ? addDays(new Date(news.endDate), 1) : new Date(news.endDate)

    const event = {
      start: {
        dateTime: `${format(new Date(startDate), 'yyyy-MM-dd')}T${startTime}`,
        timeZone: 'Europe/Paris',
      },
      end: {
        dateTime: `${format(new Date(endDate), 'yyyy-MM-dd')}T${endTime}`,
        timeZone: 'Europe/Paris',
      },
      isAllDay: !!news.entireDay,
      subject: news.title,
      location: {
        displayName: news.location || '',
      },
    }

    return msal.executeGraphRequest('/me/events', 'POST', event)
  },
}

export default api

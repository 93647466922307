import { createEvent, createStore } from 'effector'

export const actions = {
  setAnimations: createEvent<AnimationSimple[]>('setAnimations'),
  resetAnimations: createEvent('resetAnimations'),
  setReservations: createEvent<Booking[]>('setReservations'),
  resetReservations: createEvent('resetReservations'),
  setPastReservations: createEvent<Booking[]>('setPastReservations'),
  resetPastReservations: createEvent('resetPastReservations'),
  setAnimationUser: createEvent<AnimationUser>('setAnimationUser'),
  resetAnimationUser: createEvent('resetAnimationUser'),
  reset: createEvent('reset'),
}

const initialState: RegisterState = {
  animations: [],
  reservations: [],
  pastReservations: [],
  animationUser: undefined,
}

export const store = createStore<RegisterState>(initialState, { name: 'register' })
  .on(actions.setAnimations, (s, animations) => ({ ...s, animations }))
  .on(actions.resetAnimations, (s) => ({ ...s, animations: initialState.animations }))
  .on(actions.setReservations, (s, reservations) => ({ ...s, reservations }))
  .on(actions.resetReservations, (s) => ({ ...s, reservations: initialState.reservations }))
  .on(actions.setPastReservations, (s, pastReservations) => ({ ...s, pastReservations }))
  .on(actions.resetPastReservations, (s) => ({ ...s, reservations: initialState.pastReservations }))
  .on(actions.setAnimationUser, (s, animationUser) => ({ ...s, animationUser }))
  .on(actions.resetAnimationUser, (s) => ({ ...s, animationUser: initialState.animationUser }))
  .on(actions.reset, (s) => initialState)

import ws from 'utils/Webservice'

const api = {
  get: () => ws<InterCampusRouteList>(`/intercampus/routes`, 'GET'),
  getParking: (lang: string, siteId: number) =>
    ws<ParkingList>(`/parking?siteId=${siteId}`, 'GET', {
      'Accept-Language': lang,
    }),
}

export default api

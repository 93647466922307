import React from 'react'
import styled from 'theme'

import ImageItem from '../image/ImageItem'

import { breakpoints } from 'utils/breakpoints'

import { Link } from 'react-router-dom'

export const MIN_WIDTH = 200
export const MAX_WIDTH = 600
// Largeur à partir de laquelle on force l'affichage sur 3 colonnes
// Sinon on risque d'avoir une grille de plus de 3 colonnes à cause de "auto-fit"
export const WIDTH_TRESHOLD = 1270

interface Props {
  imageUrl?: string
  title: string
  description?: string
  link: string
  ariaLabel?: string
  tag?: string
  dateLabel?: string
  locationLabel?: string
  themeColor: string
  onClick?: () => void
  externalLink?: boolean
}

const ListItemRework = ({
  description,
  imageUrl,
  link,
  title,
  ariaLabel,
  tag,
  dateLabel,
  locationLabel,
  themeColor,
  onClick,
  externalLink,
}: Props) => {
  if (externalLink) {
    return (
      <ExternalItemLink href={link} aria-label={ariaLabel} onClick={onClick} target="_blank" rel="noreferrer">
        <ImageItem
          image={imageUrl || require('core/src/assets/news_default.jpeg').default}
          minWidth={MIN_WIDTH}
          maxWidth={MAX_WIDTH}
        />
        {tag && <ItemTag themeColor={themeColor}>{tag}</ItemTag>}
        <ItemTitle>{title}</ItemTitle>
        {dateLabel && locationLabel && (
          <ItemDate themeColor={themeColor}>
            {dateLabel}
            {locationLabel}
          </ItemDate>
        )}
        {!!description && <Resume dangerouslySetInnerHTML={{ __html: description }} />}
      </ExternalItemLink>
    )
  }

  return (
    <ItemLink to={link} aria-label={ariaLabel} onClick={onClick}>
      <ImageItem
        image={imageUrl || require('core/src/assets/news_default.jpeg').default}
        minWidth={MIN_WIDTH}
        maxWidth={MAX_WIDTH}
      />
      {tag && <ItemTag themeColor={themeColor}>{tag}</ItemTag>}
      <ItemTitle>{title}</ItemTitle>
      {dateLabel && locationLabel && (
        <ItemDate themeColor={themeColor}>
          {dateLabel}
          {locationLabel}
        </ItemDate>
      )}
      {!!description && <Resume dangerouslySetInnerHTML={{ __html: description }} />}
    </ItemLink>
  )
}

export default ListItemRework

const ItemLink = styled(Link)`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-decoration: none;
  min-width: ${MIN_WIDTH}px;
  max-width: ${MAX_WIDTH}px;
  transition: all 0.1s ease-in-out;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: 100%;
  }

  :hover {
    transform: scale(1.02);
  }
`

const ExternalItemLink = styled('a')`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-decoration: none;
  min-width: ${MIN_WIDTH}px;
  max-width: ${MAX_WIDTH}px;
  transition: all 0.1s ease-in-out;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: 100%;
  }

  :hover {
    transform: scale(1.02);
  }
`

const ItemTag = styled('p')<{ themeColor: string }>`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.themeColor};
  padding-bottom: 8px;
`

const ItemTitle = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
  max-height: 41px;
  margin: 0;

  padding-bottom: 8px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-wrap: break-word;
`

const ItemDate = styled('p')<{ themeColor: string }>`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.themeColor};
  padding-bottom: 16px;
`

const Resume = styled('p')`
  ${(props) => props.theme.fonts.subtitle};

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-wrap: break-word;

  span,
  div,
  h1,
  h2,
  h3,
  h4,
  p {
    font-family: ${(props) => props.theme.fonts.body.fontFamily} !important;
  }
`

import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import IconLabel from 'components/label/IconLabel'
import Loader from 'components/status/Loader'
import Alert from 'components/alert/Alert'
import Modal from 'components/modal/Modal'
import Tree from 'components/button/Tree'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Share from './Share'
import Main from 'components/main/Main'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as NewsStore from './store'
import * as SiteStore from 'site/store'

import { breakpoints } from 'utils/breakpoints'
import { computeTimeLabel, getDateLabel } from './utils'

import api from './api'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  id: string
}

const NewsDetailScreen = ({ id }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()
  const site = useReducer(SiteStore.store, (s) => s.site!)

  const [news, setNews] = React.useState<ArticleDetailV4>()
  const [nbLikes, setNbLikes] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [registering, setRegistering] = React.useState(false)

  const likes = useReducer(NewsStore.store, (s) => s.likes)
  const liked = React.useMemo(() => !!news && likes.includes(news.id), [likes, news])

  const photos = React.useMemo(
    () =>
      news?.photos
        .sort((a, b) => a.position - b.position)
        .map((p) => ({ key: p.url, url: p.url, description: p.photoDescription })),
    [news]
  )

  const fetch = () => {
    setLoading(true)
    api
      .article(id)
      .then((n) => {
        setNews(n)
        setNbLikes(n.nbLikes)
      })
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    analytics.screen({
      screen_feature: values.screens.news,
      screen_name: values.screens.newsDetail,
      screen_object_id: id,
    })

    fetch()
  }, [])

  const toggleLike = () => {
    if (news) {
      analytics.event({
        event_feature: values.eventName.news,
        event_action: liked ? values.actions.unlike : values.actions.like,
        event_object_id: id,
      })
      if (liked) {
        NewsStore.actions.setLikes(likes.filter((l) => l !== news.id))
        api.dislike(site.id, news.id)
        setNbLikes(nbLikes - 1)
      } else {
        NewsStore.actions.setLikes([...likes, news.id])
        api.like(site.id, news.id)
        setNbLikes(nbLikes + 1)
      }
    }
  }

  const register = () => {
    if (!news) {
      return null
    }

    setRegistering(true)

    analytics.event({
      event_feature: values.eventName.news,
      event_action: values.actions.agenda,
      event_object_id: id,
    })

    api
      .register(news)
      .then(() => {
        Alert.open({
          title: i18n.t('screens.news.title'),
          description: i18n.t('screens.news.calendar.succeed'),
        })
      })
      .catch(() => {
        Alert.open({
          title: i18n.t('screens.news.title'),
          description: i18n.t('screens.news.calendar.failed'),
        })
      })
      .finally(() => setRegistering(false))
  }

  const onShare = () => {
    if (news) {
      analytics.event({
        event_feature: values.eventName.news,
        event_action: values.actions.share,
        event_object_id: id,
      })
      Modal.open({
        Content: () => <Share news={news} onClose={Modal.close} />,
        closeButton: true,
      })
    }
  }

  if (loading) {
    return <Loader />
  }

  if (!news) {
    return null
  }

  const dateLabel = getDateLabel(news, i18n)
  const time = news.type === 'event' && computeTimeLabel(news, i18n)

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={`${i18n.t('screens.news.titleDetailPage')}${news.title}`} />

        <Tree previousPages={[{ url: 'news', title: 'screens.news.title' }]} currentPageTitle={news.title} />

        <ContentContainer>
          <Tag>{i18n.t(`tags.${news.tag}`)}</Tag>

          <Title>{news.title}</Title>

          <Line>
            <IconLabel label={dateLabel} icon="agenda" color={Theme.colors.darkText} />
            {!!time && <IconLabel label={time} icon="clock" color={Theme.colors.darkText} />}
            {news.type === 'event' && !!news.location && (
              <IconLabel label={news.location} icon="target" color={Theme.colors.darkText} />
            )}
          </Line>

          {!!photos && (
            <ImageContainer>
              <ItemImage src={!!photos ? photos[0].url : require('core/src/assets/news_default.jpeg').default} />
            </ImageContainer>
          )}

          <ActionsContainer>
            <IconLabel
              label={i18n.t('common.share')}
              icon="share"
              onClick={onShare}
              focusable
              ariaLabel={i18n.t(`accessibility.ariaLabels.news.share`)}
            />
            {news.type === 'event' && (
              <IconLabel
                label={i18n.t('screens.news.addToCalendar')}
                icon="agenda_add"
                onClick={register}
                disabled={registering}
                focusable
              />
            )}
            <IconLabel
              label={i18n.t(`screens.news.likes`, { count: nbLikes })}
              icon="like"
              onClick={toggleLike}
              focusable
              ariaLabel={i18n.t(`accessibility.ariaLabels.news.${liked ? 'unlike' : 'like'}`)}
            />
          </ActionsContainer>

          {!!news.resume && (
            <Head dangerouslySetInnerHTML={{ __html: news.resume.replace(/href/g, "target='_blank' href") }} />
          )}

          {news.source === 'SHAREPOINT' ? (
            <>
              <Content>{i18n.t('screens.news.warning')}</Content>
              <MyHub>
                <StyledLink
                  href={news.description}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={i18n.t('accessibility.ariaLabels.news.seeNewsMyHub')}>
                  {i18n.t('screens.news.myhub')}
                </StyledLink>
              </MyHub>
            </>
          ) : (
            <Content dangerouslySetInnerHTML={{ __html: news.description.replace(/href/g, "target='_blank' href") }} />
          )}
        </ContentContainer>
      </ScreenContainer>
    </Main>
  )
}

export default NewsDetailScreen

const ScreenContainer = styled('div')`
  display: flex;
  padding: 44px 80px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 44px 55px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 44px 35px;
  }
`

const ContentContainer = styled('div')`
  padding: 40px 200px;
  display: flex;
  flex: 1;
  width: 645px;
  justify-content: center;
  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 20px 0px;
    width: 100%;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 20px 0px;
    width: 100%;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h1Bold};
  margin: 0px 0px 30px;
`

const Line = styled('div')`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 24px;
`

const Content = styled('div')`
  ${(props) => props.theme.fonts.h3};
  padding-top: 24px;
  span,
  div,
  h1,
  h2,
  h3,
  h4,
  p {
    font-family: ${(props) => props.theme.fonts.body.fontFamily} !important;
  }
`

const ImageContainer = styled('div')`
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 297px;
  width: 400px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.functionalities.practicalinformation}0D;

  @media only screen and (max-width: ${breakpoints.small}px) {
    height: 297px;
    width: 100%;
  }
`

const ItemImage = styled('img')`
  object-fit: cover;
  user-select: none;
  aspect-ratio: 4/3;
  height: 297px;
  width: 400px;
  border-radius: 12px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    height: 297px;
    width: 100%;
  }
`

const Tag = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.darkText};
  margin: 0 0 16px;
`

const ActionsContainer = styled('div')`
  flex-direction: row;
  gap: 32px;
  margin: 24px 0;
  padding: 16px;
  border-radius: 12px;
  align-items: center;
  align-self: flex-start;
  background-color: ${(props) => props.theme.colors.contentBackground};

  @media only screen and (max-width: ${breakpoints.small}px) {
    width: calc(100% - 16px);
    padding: 16px 8px;
    gap: 0px;
    justify-content: space-between;
  }
`

const Head = styled('h2')`
  ${(props) => props.theme.fonts.h2Bold};
  margin: 20px 20px 20px 0px;
  span,
  div,
  h1,
  h2,
  h3,
  h4,
  p {
    font-family: ${(props) => props.theme.fonts.body.fontFamily} !important;
  }
`

const MyHub = styled('div')`
  align-self: center;
  width: 400px;
  margin: 25px;
`

const StyledLink = styled('a')`
  display: flex;
  padding: 15px 20px;
  align-self: center;
  text-decoration: none;
  border-radius: 50px;
  color: ${(props) => props.theme.colors.primaryText};
  background-color: ${(props) => props.theme.colors.accent};
  ${(props) => props.theme.fonts.body};
  text-align: center;
`

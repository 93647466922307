import ws from 'utils/Webservice'

const api = {
  // ANIMATIONS

  getAll: (siteId: number, status?: AnimationStatus) =>
    ws<AnimationsList>(`/animations?siteId=${siteId}&status=${status}`, 'GET'),

  getDetail: (animationId: string) => ws<AnimationDetail>(`/animations/${animationId}`, 'GET'),

  getUserInfo: (animationId: string, email: string) =>
    ws<AnimationUser>(`/animations/${animationId}/users/${email}`, 'GET'),

  // MY RESERVATIONS

  getReservations: (showPastReservations: boolean) =>
    ws<Bookings>(`/animations/me/bookings?showPastBookings=${showPastReservations}`, 'GET'),

  book: (animationId: string, slotId: string, imageRights?: ImageConsent, email?: string) => {
    const queryParams = [imageRights ? `imageRights=${imageRights}` : '', email ? `email=${email}` : '']
      .filter(Boolean)
      .join('&')
    const queryString = queryParams ? `?${queryParams}` : ''
    return ws<Booking>(`/animations/${animationId}/slots/${slotId}/bookings${queryString}`, 'POST')
  },

  modify: (animationId: string, slotId: string, bookingId: string, imageRights?: ImageConsent) =>
    ws<Booking>(
      `/animations/${animationId}/slots/${slotId}/bookings/${bookingId}${
        !!imageRights ? `?imageRights=${imageRights}` : ''
      }`,
      'POST'
    ),

  cancel: (animationId: string, slotId: string, bookingId: string) =>
    ws<Booking>(`/animations/${animationId}/slots/${slotId}/bookings/${bookingId}`, 'PUT'),

  share: (animationId: string, slotId: string, bookingId: string, email: string) =>
    ws<Booking>(`/animations/${animationId}/slots/${slotId}/bookings/${bookingId}/share?user_email=${email}`, 'POST'),

  // ALERTS

  getAlert: (animationId: string) => ws<UserList>(`/animations/${animationId}/alert`, 'GET'),

  subscribe: (animationId: string) => ws<UserList>(`/animations/${animationId}/alert`, 'POST'),

  unsubscribe: (animationId: string) => ws<UserList>(`/animations/${animationId}/alert`, 'DELETE'),
}

export default api

import api from './api'
import * as RegisterStore from './store'

import theme from 'theme/Theme'

import Logger from 'utils/Logger'
import { capitalize } from 'utils/stringUtils'
import { isAfter, isSameDay } from 'date-fns'

import { I18n } from 'i18n/i18n'

import { UserSelf } from 'store/user/user'
import { DirectoryUser } from 'directory365/types'

const DEFAULT_WIDTH = 500
const CONTENT_WIDTH = 666

const getDay = (date?: string) => (!!date ? date.split('T')[0] : '')

const getDateString = (i18n: I18n, start: string, end?: string, full = false) => {
  const startString = capitalize(
    i18n.t(`screens.register.dayMonthYear${full ? 'Full' : 'Short'}`, { date: new Date(start) })
  )
  if (!!end && !isSameDay(new Date(start), new Date(end))) {
    return `${startString} - ${capitalize(
      i18n.t(`screens.register.dayMonthYear${full ? 'Full' : 'Short'}`, { date: new Date(end) })
    )}`
  }
  return startString
}

const groupeSlotsByDay = (slots: Slot[]) =>
  slots
    .sort((a, b) => a.startDate.localeCompare(b.startDate))
    .reduce((acc, cur) => {
      const day = getDay(cur.startDate)
      const daySlots = acc[day] || []
      return { ...acc, [day]: [...daySlots, cur] }
    }, {} as { [key: string]: Slot[] })

const filterAnimations = (animations: AnimationSimple[]) =>
  animations.filter(
    (anim) =>
      !!anim.slotFirstDate && !!anim.slotLastDate && anim.slotFirstDate !== 'unknown' && anim.slotLastDate !== 'unknown'
  )

const filterValidSlots = (slots?: Slot[]) =>
  slots?.filter(isValidSlot).sort((a, b) => a.startDate.localeCompare(b.startDate)) || []

const isValidSlot = (slot: Slot) => isAfter(new Date(slot.startDate), new Date())

const isAnimationFull = (detail: AnimationDetail) => detail.isFull || !detail.slots.some(isValidSlot)

const isMultipleDays = (detail: AnimationSimple | AnimationDetail) =>
  !!detail.slotFirstDate &&
  !!detail.slotLastDate &&
  !isSameDay(new Date(detail.slotFirstDate), new Date(detail.slotLastDate))

const fetchAnimations = (siteId?: number): Promise<ScreenStatus> => {
  if (!siteId) {
    return Promise.reject('error' as ScreenStatus)
  }
  return api
    .getAll(siteId, 'CREATED')
    .then(({ animations }) => {
      RegisterStore.actions.setAnimations(filterAnimations(animations))
      return 'ok' as ScreenStatus
    })
    .catch((err) => {
      Logger.error(err)
      return 'error' as ScreenStatus
    })
}

const fetchReservations = (pastBookings: boolean): Promise<ScreenStatus> =>
  api
    .getReservations(pastBookings)
    .then(({ bookings }) => {
      const sortedBookings = bookings.sort((a, b) => a.startDate.localeCompare(b.startDate))
      pastBookings
        ? RegisterStore.actions.setPastReservations(sortedBookings)
        : RegisterStore.actions.setReservations(sortedBookings)
      return 'ok' as ScreenStatus
    })
    .catch((err) => {
      Logger.error(err)
      return 'error' as ScreenStatus
    })

const getInvitationCardDesign = (design: AnimationCardType) => {
  switch (design) {
    case 'GOLDEN_INVITATION':
      return {
        color: theme.colors.gold,
        image: require('core/src/assets/card_background_gold.png').default,
        shadow:
          '0px 5.846px 30.23px 0px rgba(0, 0, 0, 0.18), 0px 1.462px 1.462px 0px rgba(0, 0, 0, 0.25), 0px 5.846px 13.3px 0px rgba(0, 0, 0, 0.08), 0px 3.755px 14.1px 0px rgba(142, 114, 48, 0.5), 0px 3.755px 37.9px 0px rgba(142, 114, 48, 0.24)',
      }
    case 'SILVER_INVITATION':
      return {
        color: theme.colors.silver,
        image: require('core/src/assets/card_background_silver.png').default,
        shadow:
          '0px 5.846px 30.23px 0px rgba(0, 0, 0, 0.18), 0px 1.462px 1.462px 0px rgba(0, 0, 0, 0.25), 0px 5.846px 13.3px 0px rgba(0, 0, 0, 0.08), 0px 3.755px 14.1px 0px #838383, 0px 3.755px 37.9px 0px #FDFDFD',
      }
    case 'COPPER_INVITATION':
      return {
        color: theme.colors.copper,
        image: require('core/src/assets/card_background_copper.png').default,
        shadow:
          '0px 5.846px 30.23px 0px rgba(0, 0, 0, 0.18), 0px 1.462px 1.462px 0px rgba(0, 0, 0, 0.25), 0px 5.846px 13.3px 0px rgba(0, 0, 0, 0.08), 0px 3.755px 14.1px 0px rgba(142, 114, 48, 0.50), 0px 3.755px 37.9px 0px rgba(142, 114, 48, 0.24)',
      }
  }
}

const findSlot = (slotId: string, slots: Slot[]) => slots.find((slot) => slot.id === slotId)

const getUserName = (user?: UserSelf | DirectoryUser) => {
  if (!!user) {
    const { givenName, surname, displayName } = user
    return !!givenName && !!surname ? `${givenName} ${surname}` : displayName || undefined
  }
}

const isAnimationRestricted = (animation?: AnimationDetail) =>
  !!animation && (animation.restrictedToSite || animation.restrictedToContractType.length > 0)

export {
  DEFAULT_WIDTH,
  CONTENT_WIDTH,
  getDay,
  getDateString,
  getUserName,
  groupeSlotsByDay,
  filterAnimations,
  filterValidSlots,
  findSlot,
  fetchAnimations,
  fetchReservations,
  getInvitationCardDesign,
  isAnimationFull,
  isAnimationRestricted,
  isMultipleDays,
  isValidSlot,
}

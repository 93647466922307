import * as React from 'react'
import styled from 'styled-components'
import { DirectoryUser } from './types'

import { sortUsersByDisplayName, getSections, getSanitizedSection } from './utils'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import { breakpoints } from 'utils/breakpoints'

import * as SitesStore from 'site/store'
import * as ReferentielStore from 'store/referentiel/referentiel'

import apiReferentiels from 'visioglobe/api'
import api from './api'

import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import SearchBar from 'components/input/SearchBar'
import UserInfoCell from './UserInfoCell'
import Loader from 'components/status/Loader'
import Modal from 'components/modal/Modal'
import UserDetails from './UserDetails'
import MyFavorites from './MyFavorites'
import Main, { HEADER_HEIGHT } from 'components/main/Main'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

const SEARCH_MIN_LENGTH = 3

// Regex: \bword\b => Permets de détecter seulement le mot exact en entier et pas une chaine de caractère qui peut être comprise dans un autre mot
// 'i' => case-insensitive
const REGEX_FILTER_ADM = new RegExp(/\badm\b/, 'i')
const REGEX_ONLY_LETTERS = new RegExp(/^[a-zA-Z]/)

type Status = 'waitMinChars' | 'ready' | 'loading' | 'ok' | 'error'

const DirectoryScreen = () => {
  const i18n = useI18n()

  const site = useReducer(SitesStore.store, (s) => s.site)
  const referentiels = useReducer(ReferentielStore.referentielStore, (s) => s.referentiels)

  const [search, setSearch] = React.useState('')
  const [status, setStatus] = React.useState<Status>('waitMinChars')
  const [users, setUsers] = React.useState<DirectoryUser[]>([])
  const [isNextPageAvailable, setIsNextPageAvailable] = React.useState(false)
  const [alreadyTriedRetrieveReferentiels, setAlreadyTriedRetrieveReferentiels] = React.useState(false)

  React.useEffect(() => {
    analytics.screen({ screen_name: values.screens.directory })
  }, [])

  // Récupération des référentiels du site actuel si l'array du store est vide.
  // Cette tâche est effectuée ici pour éviter de faire l'appel API lorsque la modal "UserDetails" est affichée.
  // qui permettra à l'utilisateur d'accéder directement au bureau d'une personne de l'annuaire depuis l'écran détail.
  React.useEffect(() => {
    if (referentiels.length === 0 && !!site && !alreadyTriedRetrieveReferentiels) {
      apiReferentiels
        .all(site.id)
        .then((result) => ReferentielStore.actions.setReferentiels(result.referentiels))
        .catch(() => ReferentielStore.actions.setReferentiels([]))
        .finally(() => setAlreadyTriedRetrieveReferentiels(true))
      // Utilisation de ce state car certains sites ont un array de référentiels vide
      // ce qui amène une boucle infinie de ce useEffect
    }
  }, [referentiels, site])

  React.useEffect(() => {
    if (search.length >= SEARCH_MIN_LENGTH) {
      let cancelled = false
      setStatus('ready')

      const timer = setTimeout(() => {
        setStatus('loading')

        api
          .search(search)
          .then((res) => {
            if (!cancelled) {
              const filteredResults = res.value.filter(filterMSGraphResults)

              setUsers(sortUsersByDisplayName(filteredResults))
              setStatus('ok')
              setIsNextPageAvailable(!!res['@odata.nextLink'])
            }
          })
          .catch(() => {
            if (!cancelled) {
              setStatus('error')
            }
          })
      }, 500)

      return () => {
        cancelled = true
        clearTimeout(timer)
      }
    } else {
      setUsers([])
      setStatus('waitMinChars')
    }
  }, [search])

  const sections = getSections(users)

  const filterMSGraphResults = (userDetail: DirectoryUser) => {
    // Les comptes avec un mail vide et contenant le mot 'adm' dans leur nom
    if (!!userDetail.displayName && REGEX_FILTER_ADM.test(userDetail.displayName) && userDetail.mail === null) {
      return false
    }

    // Les comptes qui n'ont pas ni mail ni numéros de téléphone (Professionel ET Personnel)
    if (!userDetail.mail && !userDetail.mobilePhone && userDetail.businessPhones.length === 0) {
      return false
    }

    // Les comptes qui n'ont pas ni "surname", ni "givenName" ni "displayName"
    if (!userDetail.surname && !userDetail.givenName && !userDetail.displayName) {
      return false
    }

    return true
  }

  const renderSectionList = (section: string) => {
    const items = users.filter((user) => {
      const sanitizedSection = getSanitizedSection(user)

      if (!!user.displayName && !REGEX_ONLY_LETTERS.test(user.displayName) && section === '#') {
        return sanitizedSection
      } else {
        return sanitizedSection.startsWith(section)
      }
    })

    return (
      <StyledListItem key={section}>
        <LetterSectionContainer>
          <SectionLetter>{section}</SectionLetter>
          <GridList>{items.map(renderUser)}</GridList>
        </LetterSectionContainer>
      </StyledListItem>
    )
  }

  const renderUser = (user: DirectoryUser) => (
    <StyledListItem key={user.id}>
      <UserButton onClick={() => Modal.open({ Content: () => <UserDetails user={user} /> })}>
        <UserInfoCell user={user} isInFavoriteSection={false} />
      </UserButton>
    </StyledListItem>
  )

  return (
    <Main>
      <DirectoryContainer>
        <TitleHelmet title={i18n.t('screens.phonebook.title')} />

        <>
          <Container>
            <TopContainer role="search">
              <SearchBar
                feature="directory"
                idSearchInput="idDirectorySearch"
                value={search}
                onChange={setSearch}
                borderRadius="4px"
                iconPosition="left"
                placeholder={i18n.t('screens.phonebook.searchPlaceholder')}
              />
            </TopContainer>

            <MyFavorites />

            {status === 'ok' && isNextPageAvailable && (
              <MoreResultAvailableContainer>
                <MessageText>{i18n.t('screens.phonebook.morePageAvailable')}</MessageText>
              </MoreResultAvailableContainer>
            )}
          </Container>

          <BottomContainer>
            {status === 'loading' ? (
              <Loader />
            ) : status === 'error' ? (
              <MessageText>{i18n.t('screens.phonebook.searchError')}</MessageText>
            ) : users.length === 0 ? (
              <MessageText>
                {i18n.t(`screens.phonebook.${status !== 'waitMinChars' ? 'noResult' : 'minSearch'}`, {
                  min: SEARCH_MIN_LENGTH,
                })}
              </MessageText>
            ) : (
              <StyledList>{sections.map(renderSectionList)}</StyledList>
            )}
          </BottomContainer>
        </>
      </DirectoryContainer>
    </Main>
  )
}

// CONTAINERS

const DirectoryContainer = styled('div')`
  display: flex;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);

  @media only screen and (max-width: ${breakpoints.phone}px) {
    overflow-x: hidden;
    position: relative;
    width: 100vw;
  }
`

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  max-width: 564px;
  gap: 30px;
  padding: 0 165px;
  min-width: 500px;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 0 90px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 0 70px;
    min-width: 300px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 0 35px;
  }
`

const TopContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
`

const BottomContainer = styled('div')`
  flex: 1;
  background-color: ${(props) => props.theme.colors.contentBackground};
  padding: 34px 165px;
  height: fit-content;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 34px 90px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 34px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 34px 35px;
  }
`

const MoreResultAvailableContainer = styled(BottomContainer)`
  flex: unset;
  margin: 10px 0;
`

const LetterSectionContainer = styled('div')`
  margin-bottom: 15px;
`

const UserButton = styled('button')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};
  max-width: 360px;
  margin: 15px 30px 15px 0px;
  padding: 18px 17px;
  border-radius: 15px;
  text-align: start;
  border: 0px;

  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    margin: 15px 0px;
  }
`

const SectionLetter = styled('h2')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
  text-transform: capitalize;
`

const MessageText = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
`

// LISTS

const GridList = styled('ol')`
  display: grid;
  grid-template-columns: repeat(auto-fit, 390px);
  list-style: none;
  padding: 0;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    display: flex;
    flex-direction: column;
  }
`

const StyledList = styled('ol')`
  list-style: none;
  padding: 0;
  margin: 0;
`

const StyledListItem = styled('li')`
  display: grid;
`

export default DirectoryScreen

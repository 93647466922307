const icons: { [key in IconName]: string } = {
  access_control: require('./svg/access_control.svg').default,
  add_contact: require('./svg/add_contact.svg').default,
  agenda: require('./svg/agenda.svg').default,
  agenda_add: require('./svg/agenda_add.svg').default,
  agenda_filled: require('./svg/agenda_filled.svg').default,
  arrow_updown: require('./svg/arrow_updown.svg').default,
  authentification: require('./svg/authentification.svg').default,
  bell: require('./svg/bell.svg').default,
  blablacar: require('./svg/blablacar.svg').default,
  blinds: require('./svg/blinds.svg').default,
  book: require('./svg/book.svg').default,
  bottom: require('./svg/bottom.svg').default,
  bubble: require('./svg/bubble.svg').default,
  building_illed: require('./svg/building_illed.svg').default,
  building: require('./svg/building.svg').default,
  burger_menu: require('./svg/burger_menu.svg').default,
  car: require('./svg/car.svg').default,
  car_rental: require('./svg/car_rental.svg').default,
  check_circle: require('./svg/check_circle.svg').default,
  check: require('./svg/check.svg').default,
  chevron_bottom: require('./svg/chevron_bottom.svg').default,
  chevron_left: require('./svg/chevron_left.svg').default,
  chevron_right: require('./svg/chevron_right.svg').default,
  chevron_top: require('./svg/chevron_top.svg').default,
  circle_chevron_left: require('./svg/circle_chevron_left.svg').default,
  clock: require('./svg/clock.svg').default,
  co2: require('./svg/co2.svg').default,
  compass: require('./svg/compass.svg').default,
  copy: require('./svg/copy.svg').default,
  cross: require('./svg/cross.svg').default,
  desk: require('./svg/desk.svg').default,
  desk_alt: require('./svg/desk_alt.svg').default,
  directory: require('./svg/directory.svg').default,
  directory_alt: require('./svg/directory_alt.svg').default,
  double_chevron_left: require('./svg/double_chevron_left.svg').default,
  double_chevron_right: require('./svg/double_chevron_right.svg').default,
  drops: require('./svg/drops.svg').default,
  electric: require('./svg/electric.svg').default,
  exit: require('./svg/exit.svg').default,
  external: require('./svg/external.svg').default,
  fan: require('./svg/fan.svg').default,
  flame: require('./svg/flame.svg').default,
  geoloc: require('./svg/geoloc.svg').default,
  globe: require('./svg/globe.svg').default,
  group: require('./svg/group.svg').default,
  groups2: require('./svg/groups2.svg').default,
  heart_filled: require('./svg/heart_filled.svg').default,
  heart: require('./svg/heart.svg').default,
  hide: require('./svg/hide.svg').default,
  home: require('./svg/home.svg').default,
  hour: require('./svg/hour.svg').default,
  info: require('./svg/info.svg').default,
  info_alt: require('./svg/info_alt.svg').default,
  information_desk: require('./svg/information_desk.svg').default,
  invitation: require('./svg/invitation.svg').default,
  less: require('./svg/less.svg').default,
  light_bulb: require('./svg/light_bulb.svg').default,
  light_off: require('./svg/light_off.svg').default,
  light_on: require('./svg/light_on.svg').default,
  like: require('./svg/like.svg').default,
  locker: require('./svg/locker.svg').default,
  logout: require('./svg/logout.svg').default,
  mail: require('./svg/mail.svg').default,
  map: require('./svg/map.svg').default,
  meetme: require('./svg/meetme.svg').default,
  mobility: require('./svg/mobility.svg').default,
  mobility_bus: require('./svg/mobility_bus.svg').default,
  mobility_co2: require('./svg/mobility_co2.svg').default,
  mobility_pieton: require('./svg/mobility_pieton.svg').default,
  mobility_velo: require('./svg/mobility_velo.svg').default,
  mobility_voiture: require('./svg/mobility_voiture.svg').default,
  navigate: require('./svg/navigate.svg').default,
  news: require('./svg/news.svg').default,
  news_alt: require('./svg/news_alt.svg').default,
  no_position: require('./svg/no_position.svg').default,
  one_hour: require('./svg/one_hour.svg').default,
  options: require('./svg/options.svg').default,
  padlock: require('./svg/padlock.svg').default,
  parking: require('./svg/parking.svg').default,
  pdf: require('./svg/pdf.svg').default,
  pencil: require('./svg/pencil.svg').default,
  people: require('./svg/people.svg').default,
  phone: require('./svg/phone.svg').default,
  pin: require('./svg/pin.svg').default,
  pin_alt: require('./svg/pin_alt.svg').default,
  plus: require('./svg/plus.svg').default,
  pmr: require('./svg/pmr.svg').default,
  pmr_bold: require('./svg/pmr_bold.svg').default,
  point: require('./svg/point.svg').default,
  profile: require('./svg/profile.svg').default,
  push: require('./svg/push.svg').default,
  question_round: require('./svg/question_round.svg').default,
  qrcode: require('./svg/qrcode.svg').default,
  quiz: require('./svg/quiz.svg').default,
  rate: require('./svg/rate.svg').default,
  register: require('./svg/register.svg').default,
  remote: require('./svg/remote.svg').default,
  reset: require('./svg/reset.svg').default,
  restaurant: require('./svg/restaurant.svg').default,
  restaurant_alt: require('./svg/restaurant_alt.svg').default,
  right: require('./svg/right.svg').default,
  roombooking: require('./svg/roombooking.svg').default,
  room: require('./svg/room.svg').default,
  route: require('./svg/route.svg').default,
  safe: require('./svg/safe.svg').default,
  scan: require('./svg/scan.svg').default,
  search: require('./svg/search.svg').default,
  send: require('./svg/send.svg').default,
  settings: require('./svg/settings.svg').default,
  settings_alt: require('./svg/settings_alt.svg').default,
  share: require('./svg/share.svg').default,
  snowflake: require('./svg/snowflake.svg').default,
  stairs: require('./svg/stairs.svg').default,
  star_filled: require('./svg/star_filled.svg').default,
  star: require('./svg/star.svg').default,
  store_1: require('./svg/store_1.svg').default,
  store_2: require('./svg/store_2.svg').default,
  store_3: require('./svg/store_3.svg').default,
  store_4: require('./svg/store_4.svg').default,
  subscribe: require('./svg/subscribe.svg').default,
  survey: require('./svg/survey.svg').default,
  switch: require('./svg/switch.svg').default,
  target: require('./svg/target.svg').default,
  text: require('./svg/text.svg').default,
  thermometer: require('./svg/thermometer.svg').default,
  ticket: require('./svg/ticket.svg').default,
  transfer: require('./svg/transfer.svg').default,
  transport: require('./svg/transport.svg').default,
  transport_alt: require('./svg/transport_alt.svg').default,
  trash: require('./svg/trash.svg').default,
  undo: require('./svg/undo.svg').default,
  unsubscribe: require('./svg/unsubscribe.svg').default,
  updown: require('./svg/updown.svg').default,
  user: require('./svg/user.svg').default,
  user_alt: require('./svg/user_alt.svg').default,
  warning: require('./svg/warning.svg').default,
  warning_round: require('./svg/warning_round.svg').default,
  warning_unfilled: require('./svg/warning_unfilled.svg').default,
  service: require('./svg/service.svg').default,
  web: require('./svg/web.svg').default,
  wind: require('./svg/wind.svg').default,
}

export default icons

import * as React from 'react'
import styled from 'theme/styled-components'

import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'

import Screens from 'core/src/layout/Screens'
import useNavigation from 'core/src/layout/useNavigation'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SitesStore from 'site/store'

import { breakpoints } from 'utils/breakpoints'

const MOVE_SCREENS: ScreenName[] = ['intercampus', 'transport', 'mobility']

const MoveScreen = () => {
  const i18n = useI18n()

  const navigation = useNavigation()

  const site = useReducer(SitesStore.store, (s) => s.site)

  const data = React.useMemo(() => {
    site?.functionalities.find((f) => f.type === 'PARKING' && f.activated)
      ? !MOVE_SCREENS.includes('parking') && MOVE_SCREENS.push('parking')
      : MOVE_SCREENS.includes('parking') && MOVE_SCREENS.splice(MOVE_SCREENS.indexOf('parking'), 1)

    return MOVE_SCREENS.filter((s) => site?.functionalities.some((f) => s !== 'transport' || f.type === 'TRANSPORT'))
      .map((s) => Screens[s])
      .filter(Boolean)
  }, [site])

  const renderItem = (item: ScreenDefinition) => (
    <ItemContainer key={item.name} onClick={() => navigation.push(item.path)}>
      <ItemTitle>{i18n.t(`screens.${item.name}.title`)}</ItemTitle>
      <Image src={require(`core/src/assets/move_${item.name}.png`).default} />
    </ItemContainer>
  )

  const pageTitle = i18n.t(`screens.move.title${!!site && site.alternativeFunctionalityDesign ? '_alt' : ''}`)

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={pageTitle} />
        <OptionsContainer>{data.map(renderItem)}</OptionsContainer>
      </ScreenContainer>
    </Main>
  )
}

export default MoveScreen

const ScreenContainer = styled('div')`
  padding: 50px 24px 50px 165px;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding-left: 90px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding-left: 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding-left: 35px;
  }
`

const OptionsContainer = styled('div')`
  display: grid;
  list-style: none;
  padding: 16px 0px;
  margin: 0;
  grid-template-columns: repeat(auto-fit, 430px);
  gap: 40px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`

const ItemContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  max-height: 220px;
  border-radius: 15px;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 2px 3px 0px ${(props) => props.theme.colors.lightGrey};

  :hover {
    cursor: pointer;
  }
`

const ItemTitle = styled('p')`
  ${(props) => props.theme.fonts.h2Bold};
  text-align: center;
  margin: 12px 0px;
`

const Image = styled('img')`
  height: 155px;
  width: 100%;
  /* width: 300px; */
  object-fit: cover;
`

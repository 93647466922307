import { setHours, setMinutes } from 'date-fns'

const getWeeklySchedules = (restaurant: RestaurantV3) => {
  const firstWeeklySchedules =
    restaurant.weeklySchedules && restaurant.weeklySchedules.length > 0 ? restaurant.weeklySchedules[0] : undefined

  return { schedules: firstWeeklySchedules?.dailySchedules, comment: firstWeeklySchedules?.comment }
}

const getDaySchedule = (restaurant: RestaurantV3, dayNumber?: number) => {
  const { schedules } = getWeeklySchedules(restaurant)

  // 0 correspond à dimanche, mais on souhaite que dimanche corresponde à 7
  const dayInitial = dayNumber || new Date().getDay()
  const day = dayInitial === 0 ? 7 : dayInitial

  return schedules?.find((s) => s.day.toString() === day.toString())
}

const getDateFromSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds - hours * 3600) / 60)
  return setMinutes(setHours(new Date(), hours), minutes)
}

export { getWeeklySchedules, getDaySchedule, getDateFromSeconds }
